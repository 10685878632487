<template>
  <div>

    <b-card>
      <line-chart
          :labels="ticketsQuantitiesXAxis"
          :series="ticketsQuantitiesYAxis"
          :is-y-currency="false"
          :colors="[newColor, pendingColor, closedColor]"
          :withLegend="true"
      />
    </b-card>

    <b-row v-if="('totals' in ticketsIndicator)">
      <!--      Quantities-->
      <b-col md="4">
        <!--        New-->
        <b-card :style="'color: '+newColor">
          <div>
            <h1 :style="'color: '+newColor">{{ ticketsIndicator.totals.news }}</h1>
          </div>
          <div>
            {{ capitalize($tc('newTicket(total)', ticketsIndicator.totals.news)) }}
          </div>
        </b-card>

        <!--        Pending-->
        <b-card :style="'color: '+pendingColor">
          <div>
            <h1 :style="'color: '+pendingColor">{{ ticketsIndicator.totals.pending }}</h1>
          </div>
          <div>
            {{ capitalize($tc('pendingTicket(total)', ticketsIndicator.totals.pending)) }}
          </div>
        </b-card>

        <!--        Closed-->
        <b-card :style="'color: '+closedColor">
          <div>
            <h1 :style="'color: '+closedColor">{{ ticketsIndicator.totals.closed }}</h1>
          </div>
          <div>
            {{ capitalize($tc('closedTicket(total)', ticketsIndicator.totals.closed)) }}
          </div>
        </b-card>
      </b-col>

      <b-col md="2"></b-col>

      <!--      Average times-->
      <b-col md="6">
        <b-card class="w-100">
          <column-chart
              :series="ticketsAverageTimeYAxix"
              :labels="ticketsAverageTimeXAxix"
              :colors="['#008ffb', '#26a69a', '#ff4560']"
          />
        </b-card>

      </b-col>

    </b-row>


  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../utils/filter'

import useAPI from '../../../utils/useAPI'
import moment from 'moment'
import i18n from '../../../libs/i18n'
import LineChart from '../../../components/chart/Line'
import ColumnChart from '../../../components/chart/Columns'

export default {
  components: {
    LineChart,
    ColumnChart
  },
  props: {},
  setup () {
    const { fetchTicketsIndicator, ticketsIndicator } = useAPI()
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const newColor = ref('#008ffb')
    const pendingColor = ref('#c0c0c0')
    const closedColor = ref('#00e396')

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const ticketsQuantitiesXAxis = computed(() => {
      let days = []

      if (typeof ticketsIndicator.value !== 'undefined' && ('detailByDates' in ticketsIndicator.value)) {
        Object.keys(ticketsIndicator.value.detailByDates).forEach(date => days.push(moment(date).format('L')))
      }

      return days
    })

    const ticketsQuantitiesYAxis = computed(() => {
      let output = [{
        name: capitalize(i18n.tc('newTicket', 2)),
        data: []
      }, {
        name: capitalize(i18n.tc('pendingTicket', 2)),
        data: []
      }, {
        name: capitalize(i18n.tc('closedTicket', 2)),
        data: []
      }]

      if (typeof ticketsIndicator.value !== 'undefined' && ('detailByDates' in ticketsIndicator.value)) {
        Object.keys(ticketsIndicator.value.detailByDates).forEach((date, index) => {
          output[0].data.push(ticketsIndicator.value.detailByDates[date].news.quantity)
          output[1].data.push(ticketsIndicator.value.detailByDates[date].pending.quantity)
          output[2].data.push(ticketsIndicator.value.detailByDates[date].closed.quantity)
        })
      }

      return output
    })

    const ticketsAverageTimeXAxix = computed(() => {
      // return ['normal', 'high', 'urgent']
      return [capitalize(i18n.t('averageTimesToResolve(hours)'))]
    })

    const ticketsAverageTimeYAxix = computed(() => {
      let output = [{
        name: capitalize(i18n.t('normalPriority')),
        data: [0]
      },{
        name: capitalize(i18n.t('highPriority')),
        data: [0]
      },{
        name: capitalize(i18n.t('urgentPriority')),
        data: [0]
      }]


      if (typeof ticketsIndicator.value !== 'undefined' && ('detailByDates' in ticketsIndicator.value)) {
        output[0].data = [parseInt(ticketsIndicator.value.averageTimesToResolve.normal)]
        output[1].data = [parseInt(ticketsIndicator.value.averageTimesToResolve.high)]
        output[2].data = [parseInt(ticketsIndicator.value.averageTimesToResolve.urgent)]
      }

      return output
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchTicketsIndicator()

    return {
      // Components
      capitalize,

      // Data
      newColor,
      pendingColor,
      closedColor,

      // Computed
      ticketsIndicator,
      ticketsQuantitiesXAxis,
      ticketsQuantitiesYAxis,
      ticketsAverageTimeXAxix,
      ticketsAverageTimeYAxix

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>