<template>
  <vue-apex-charts
      type="line"
      height="240"
      :options="chartOptions"
      :series="series"
  />
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../utils/filter'

import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'

export default {
  components: {
    VueApexCharts
  },
  props: {
    series: {
      type: Array,
      default: () => []
    },
    labels: {
      type: Array,
      default: () => []
    },
    isXDate: {
      type: Boolean,
      default: true
    },
    isYCurrency: {
      type: Boolean,
      default: true
    },
    colors: {
      type: Array,
      default: () => ['#7367f0', '#df87f2']
    },
    withLegend: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const chartOptions = computed(() => {
      return {
        chart: {
          toolbar: { show: false },
          zoom: { enabled: false },
          type: 'line',
          dropShadow: {
            enabled: true,
            top: 18,
            left: 2,
            blur: 5,
            opacity: 0.2,
          },
          offsetX: -10,
          events: {
            click: function (event, chartContext, config) {
              if (config.dataPointIndex != -1) {
                emit('click', config.dataPointIndex)
              }
            },
          }
        },
        stroke: {
          curve: 'smooth',
          width: 4,
        },
        grid: {
          borderColor: '#ebe9f1',
          padding: {
            top: -20,
            bottom: 5,
            left: 20,
          },
        },
        legend: {
          show: props.withLegend,
        },
        colors: props.colors,
        // fill: {
        //   type: 'gradient',
        //   gradient: {
        //     shade: 'dark',
        //     inverseColors: false,
        //     gradientToColors: ['#7367f0'],
        //     shadeIntensity: 1,
        //     type: 'horizontal',
        //     opacityFrom: 1,
        //     opacityTo: 1,
        //     stops: [0, 100, 100, 100],
        //   },
        // },
        markers: {
          size: 0,
          hover: {
            size: 5,
          },
        },
        xaxis: {
          labels: {
            offsetY: 5,
            style: {
              colors: '#b9b9c3',
              fontSize: '0.857rem',
            },
            rotate: -45,
            // rotateAlways: true,
            formatter (val) {
              if (val != null) {
                if (props.isXDate) {
                  return capitalize(moment(val).format("MMMM YYYY"))
                } else {
                  return val
                }
              }
            },
          },
          axisTicks: {
            show: false,
          },
          categories: props.labels,
          axisBorder: {
            show: false,
          },
          tickPlacement: 'on',
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            style: {
              colors: '#b9b9c3',
              fontSize: '0.857rem',
            },
            formatter (val) {
              if (val != null) {
                if (props.isYCurrency) {
                  return currency(val, true)
                } else {
                  return val
                }
              }
            },
          },
        },
        tooltip: {
          // x: { show: false },
        },
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      chartOptions,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>